/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Firma Czamaninek stawia na ciągły rozwój i wychodzi naprzeciw oczekiwaniom klienta, w związku z czym postanowiliśmy utworzyć nowy dział, którego zadaniem będzie wsparcie i pomoc w doborze rozwiązań dopasowanych do indywidualnego zapotrzebowania."), "\n", React.createElement(_components.p, null, "Jeśli masz pytanie dotyczące zakupu materiału na budowę lub masz problem z projektem Twojego domu - Napisz lub zadzwoń!"), "\n", React.createElement("div", {
    class: "contact-data-container"
  }, React.createElement("div", {
    class: "contact-card"
  }, React.createElement("div", {
    class: "contact-data"
  }, React.createElement("div", {
    class: "name"
  }, "Ramona Malinowska"), React.createElement("div", {
    class: "position"
  }, "Specjalista ds. Sprzedaży"), React.createElement("div", {
    class: "phone"
  }, React.createElement("a", {
    href: "tel:0048723419466"
  }, " +48 723 419 466")), React.createElement("div", {
    class: "email"
  }, React.createElement("a", {
    href: "mailto:ramona.malinowska@czamaninek.pl"
  }, " ramona.malinowska@czamaninek.pl")))), React.createElement("div", {
    class: "contact-card"
  }, React.createElement("div", {
    class: "contact-data"
  }, React.createElement("div", {
    class: "name"
  }, "Anna Makowska"), React.createElement("div", {
    class: "position"
  }, "Dyrektor ds. Rozwoju"), React.createElement("div", {
    class: "phone"
  }, React.createElement("a", {
    href: "tel:0048722164944"
  }, " +48 722 164 944")), React.createElement("div", {
    class: "email"
  }, React.createElement("a", {
    href: "mailto:anna.makowska@czamaninek.pl"
  }, " anna.makowska@czamaninek.pl"))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
